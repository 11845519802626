<i18n>
  {
    "ru": {
      "title": "Новая рассылка"
    },
    "he": {
      "title": "רשימת תפוצה חדשה"
    }
  }
  </i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      :color="snackbar.color"
      centered
    >
      <div class="text-center" v-html="snackbar.text"></div>
    </v-snackbar>

    <v-row class="align-center mb-5">
      <template v-for="(item, index) in clientsFilter">
        <v-col
          cols="3"
          :key="`filter-item-${index}`"
        >
          <template v-if="item.type === 'select'">
            <v-select
              v-model="item.value"
              :items="item.list"
              item-text="name"
              item-value="id"
              :label="item.name"
              color="teal"
              clearable
              @change="changeFilter()"
            ></v-select>
          </template>
        </v-col>
      </template>

      <v-col
        cols="auto ms-auto"
      >
        {{ $t('recipients') }}: {{ total }}
      </v-col>
    </v-row>

    <v-form v-model="valid">
      <v-row class="mb-5">
        <template v-for="(item, index) in propsMessage">
          <v-col
            cols="12"
            :key="`prop-item-message-${index}`"
          >
            <v-textarea
              v-model="item.value"
              :label="item.name"
              :disabled="item.disabled"
              :required="item.required"
              :rules="item.rules"
              color="teal"
            ></v-textarea>
          </v-col>
        </template>
      </v-row>

      <v-row class="mt-5">
        <v-col
          cols="auto"
        >
          <v-btn
            color="teal"
            dark
            :loading="btnLoading"
            @click="submit()"
          >{{ $t('btn.save') }}</v-btn>
        </v-col>

        <v-col
          cols="auto"
        >
          <v-btn
            color="grey lighten-5"
            to="/send-messages/"
          >{{ $t('btn.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'SendMessagesAdd',
  data: (vm) => ({
    valid: false,
    btnLoading: false,
    snackbar: {},
    props: [
      {
        name: vm.$t('props.UF_MESSAGE'),
        code: 'UF_TEXT',
        type: 'textarea',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: '',
        group: 'message',
      },
    ],
    clientsFilter: [
      {
        name: vm.$t('props.UF_TYPE'),
        code: 'UF_TYPE',
        value: null,
        type: 'select',
        list: [
          {
            id: null,
            name: vm.$t('all'),
          },
          {
            id: 1,
            name: vm.$t('clientType.entity'),
          },
          {
            id: 2,
            name: vm.$t('clientType.individual'),
          },
        ],
      },
      {
        name: vm.$t('props.UF_LAST_ORDER_DATE'),
        code: '<=UF_LAST_ORDER_DATE',
        value: null,
        type: 'select',
        list: [
          {
            id: null,
            name: vm.$t('any'),
          },
          {
            id: 30,
            name: vm.$t('daysAgo').replace('#DAYS#', 30),
          },
          {
            id: 60,
            name: vm.$t('daysAgo').replace('#DAYS#', 60),
          },
          {
            id: 90,
            name: vm.$t('daysAgo').replace('#DAYS#', 90),
          },
          {
            id: 365,
            name: vm.$t('daysAgo').replace('#DAYS#', 365),
          },
        ],
      },
    ],
    clients: [],
    page: 1,
    limit: 100,
    total: 0,
    loading: false,
  }),
  computed: {
    propsMessage() {
      return this.props.filter((item) => item.group === 'message');
    },
    paramsFilter() {
      const params = {};
      const filterProps = this.clientsFilter.filter((item) => item.value || item.value === false);

      filterProps.forEach((item) => {
        let { value } = item;

        if (item.code === '<=UF_LAST_ORDER_DATE') {
          const date = new Date();
          date.setDate(date.getDate() - item.value);

          const day = `0${date.getDate()}`.slice(-2);
          const month = `0${date.getMonth() + 1}`.slice(-2);
          const year = date.getFullYear();

          value = `${day}.${month}.${year}`;
        }

        params[item.code] = value;
      });

      params.UF_ACTIVE = '1';
      params['!UF_PHONE_NOTICE'] = '';

      return params;
    },
    clientsId() {
      return this.clients.map((item) => item.ID);
    },
    countClients() {
      return this.clients.length;
    },
  },
  methods: {
    changeFilter() {
      this.page = 1;
      this.limit = 1;
      this.clients = [];
      this.getClients();
    },
    getClients() {
      return new Promise((resolve) => {
        this.loading = true;

        const params = {
          order: {
            ID: 'ASC',
          },
          filter: this.paramsFilter,
          select: [
            'ID',
          ],
          limit: this.limit,
          offset: (this.page - 1) * this.limit,
        };

        this.$store.dispatch('getClients', { params }).then((response) => {
          this.clients = this.clients.concat(response.data.data.result);
          this.total = response.data.data.count;

          this.loading = false;

          resolve();
        });
      });
    },
    submit() {
      if (this.valid && this.total > 0) {
        this.btnLoading = true;
        this.page = 0;
        this.limit = 100;
        this.clients = [];

        this.getAllClients();
      } else {
        this.snackbar = {
          show: true,
          color: 'red darken-3',
          text: this.$t('errorEmptyProps'),
        };
      }
    },
    getAllClients() {
      this.page += 1;

      this.getClients().then(() => {
        if (this.total > this.countClients) {
          this.getAllClients();
        } else {
          this.save();
        }
      });
    },
    save() {
      const params = new FormData();
      const props = {};

      this.props.forEach((prop) => {
        props[prop.code] = prop.value;
      });

      props.UF_TYPE = 13;
      props.UF_CLIENTS = this.clientsId;

      params.append('params', JSON.stringify(props));

      this.$store.dispatch('addSendMessages', params).then((response) => {
        const { success } = response.data.data;

        this.btnLoading = false;
        this.snackbar = {
          show: true,
          color: (success) ? 'green darken-3' : 'red darken-3',
          text: response.data.data.message,
        };

        if (success) {
          setTimeout(() => {
            this.$router.push('/send-messages/');
          }, 1500);
        }
      });
    },
    setDefaultText(text) {
      const findItem = this.props.find((item) => item.code === 'UF_TEXT');
      if (findItem) {
        findItem.value = text;
      }
    },
  },
  created() {
    this.getClients();

    if (this.$route.query.text) {
      this.setDefaultText(this.$route.query.text);
    }
  },
};
</script>

<style>

</style>
